.react-datepicker {
  border: none;
  color: unset;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker__day:focus-visible {
  outline: none !important;
}

.react-datepicker__day--disabled {
  opacity: .5;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent !important;
  cursor: default;
}

.react-datepicker__month-container {
  width: 100%;
}

/* Light */
.light .react-datepicker {
  box-shadow: var(--chakra-shadows-lg);
}

.light .react-datepicker__header {
  background-color: var(--chakra-colors-gray-50);
  border-bottom: 1px solid var(--chakra-colors-gray-200);
}

.light .react-datepicker__day:hover, .light .react-datepicker__day--keyboard-selected {
  background-color: var(--chakra-colors-gray-200);
  color: var(--chakra-colors-gray-800);
}

.light .react-datepicker__day--selected {
  background-color: var(--chakra-colors-brand-400);
}

/* Dark */

.dark .react-datepicker {
  background-color: var(--chakra-colors-gray-700);
  box-shadow: var(--chakra-shadows-dark-lg);
}

.dark .react-datepicker__header {
  background-color: var(--chakra-colors-gray-800);
  border-bottom: 1px solid var(--chakra-colors-gray-400);
}

.dark .react-datepicker__day-name {
  color: var(--chakra-colors-whiteAlpha-900) !important
}

.dark .react-datepicker__day {
  color: var(--chakra-colors-whiteAlpha-900) !important;
}

.dark .react-datepicker__day:hover, .dark .react-datepicker__day--keyboard-selected {
  background-color: var(--chakra-colors-gray-400);
}

.dark .react-datepicker__day--selected {
  background-color: var(--chakra-colors-brand-500);
}