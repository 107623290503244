img:not([src]) {
  visibility: hidden;
}

.chakra-toast__inner {
  max-width: unset !important;
}

*[data-focus] {
  box-shadow: none !important;
}